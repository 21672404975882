<template>
  <div class="newsList">
    <div class="left">
      <img :src="info.img_url" alt="" />
    </div>
    <div class="right">
      <div class="title">
        {{ info.news_title }}
      </div>
      <p>
        {{ info.news_content[0] }}
      </p>
      <div class="jump">
        <span>{{ info.create_time }}</span>
        <router-link :to="{ name: 'newsDetail', params: { id: info.id } }">
          <img src="@/assets/images/arrow.png" alt="" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newsList",
  props: {
    info: {
      type: Object,
      default() {
        return {};
      }
    }
  }
};
</script>

<style lang="scss">
.newsList {
  width: 100%;
  height: 310px;
  margin-bottom: 51px;
  .left {
    float: left;
    width: 550px;
    margin-right: 50px;
    img {
      width: 100%;
    }
  }
  .right {
    width: 511px;
    float: left;
    padding: 17px 0;
    .title {
      font-size: 24px;
      font-weight: 400;
      color: rgba(34, 34, 34, 1);
      line-height: 33px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      height: 102px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 34px;
      margin-bottom: 44px;
    }
    .jump {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      span {
        font-size: 14px;
        font-weight: 400;
        color: rgba(204, 204, 204, 1);
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        width: 126px;
        height: 43px;
        border: 1px solid rgba(221, 221, 221, 1);
      }
    }
  }
}
.newsList:hover {
  .title {
    color: rgba(5, 100, 205, 1);
  }
  .jump {
    a {
      border: 2px solid rgba(5, 100, 205, 1);
    }
  }
}
</style>
