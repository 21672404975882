<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <Header title="news"></Header>
    <BannerTitle title="news"></BannerTitle>
    <div class="newsBox">
      <div v-for="item of list" :key="`0+${item.id}`">
        <NewsList :info="item"></NewsList>
      </div>
      <Paging :pages="pages" :page="page" :key="pages" @pagechange="getList">
      </Paging>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import NewsList from "../../components/newsList.vue";
import newsData from "@/assets/json/newsData.json";
import Paging from "../../components/paging.vue";
export default {
  name: "newsList",
  components: {
    Header,
    BannerTitle,
    Footer,
    NewsList,
    Paging
  },
  data() {
    return {
      page: 1, //页数
      pageSize: 4, //每页个数
      pages: 0, //总页数
      total: newsData.newsList.length, //总条数
      list: []
    };
  },
  methods: {
    pageTotal: function(rowCount, pageSize) {
      if (rowCount == null || rowCount == "") {
        return 0;
      } else {
        if (pageSize != 0 && rowCount % pageSize == 0) {
          return parseInt(rowCount / pageSize);
        }
        if (pageSize != 0 && rowCount % pageSize != 0) {
          return parseInt(rowCount / pageSize) + 1;
        }
      }
    },
    getList: function(page) {
      this.list = newsData.newsList.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    }
  },
  created() {
    this.pages = this.pageTotal(this.total, this.pageSize);
    this.getList(this.page);
  }
};
</script>

<style lang="scss">
.newsBox {
  width: 1111px;
  clear: both;
  overflow: hidden;
  margin: 23px auto;
}
</style>
