<template>
  <div class="pageBox">
    <ul v-for="index of pages" :key="`page${index}`">
      <li :class="index == checkPage ? 'check' : ''" @click="current(index)">
        {{ index }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pading",
  props: ["pages", "page"],
  data() {
    return {
      checkPage: this.page
    };
  },
  methods: {
    current: function(page) {
      this.checkPage = page;
      this.$emit("pagechange", page);
    }
  }
};
</script>

<style lang="scss">
.pageBox {
  height: 44px;
  display: flex;
  justify-content: center;
  li {
    width: 42px;
    height: 42px;
    line-height: 42px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(221, 221, 221, 1);
    color: rgba(34, 34, 34, 1);
    text-align: center;
    float: left;
    margin-right: 20px;
  }
  .check {
    background: rgba(5, 100, 205, 1);
  }
}
</style>
